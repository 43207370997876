import { mapState, mapMutations } from 'vuex'
import {
    querys,
    passwordPattren,
    formatNormalErrorMessage,
    isFromShopping,
    urlRedirection,
} from '../util'
import { emailSignupSer } from '../service'
import { postAddSubscribe } from 'public/src/pages/common/biz_helper/subscribe'
import { geetestChallenge } from 'public/src/pages/common/RiskCommon/common/geetestChallenge.js'
import { reportSignupMarketing } from '@login/utils/loginAnalysis/signupMarketing.js'
import { Metric_Register_Detail_Click_Result_Total } from '@login/utils/monitorLog.js'
import { riskVerifyChallenge } from '@login/common/riskVerifyManager.js'
import analysisIndex from '../analysis/index'
import { saSend } from '../analysis/util'
const { sensorsSend } = analysisIndex

const { langPath, host, SiteUID } = gbCommonInfo
let delayPasswordRankTimer = null

export default {
  data: () => ({
    password: '',
    passwordTip: { show: false, txt: '' },
    riskVerify: null,
    safety: { // 密码等级
      showFir: 0,
      showSec: 0,
      showThi: 0,
      showAll: 0,
      showFour: 0,
      hideAll: 1
    },
    checkEmailSubscrib: false,
    checkPhoneSubsrib: false,
    popLogin: {
      show: false,
    },
    SIGNUP_SUBSCRIPTION_SUPPORT: gbCommonInfo.SIGNUP_SUBSCRIPTION_SUPPORT,
    SIGNUP_SUBSCRIPTION_NO_SUPPORT: gbCommonInfo.SIGNUP_SUBSCRIPTION_NO_SUPPORT,
  }),
  computed: {
    ...mapState('login', ['phoneAbt', 'newPrivacyAbt', 'commonAbt',  'relatedAcc', 'newUIStates', 'preloadData']),
  },
  methods: {
    ...mapMutations('login', ['changePageShow', 'setPhoneBind', 'changeDialogs', 'setnewUIStates', 'assignState', 'setSuccessPopConfig']),
    handleBlurPsd() {
      const psd = this.password.trim()
      if (psd === '') {
        this.isFocusPsd = false
      }
      this.checkPasswordRank()
    },
    // 检测密码等级
    checkPasswordRank() {
      const password = this.password
      //   const rawPsd = password.replace(/\s+/g, '')

      if (password.length >= 8) {
        this.safety.showFir = 1
      } else {
        this.safety.showFir = 0
      }

      if (/[a-zA-Z]+/.test(password)) {
        this.safety.showSec = 1
      } else {
        this.safety.showSec = 0
      }
      if (/[0-9]+/.test(password)) {
        this.safety.showThi = 1
      } else {
        this.safety.showThi = 0
      }
      if (/\s/g.test(password)) {
        this.safety.showFour = 0
      } else {
        this.safety.showFour = 1
      }
      if (!passwordPattren.test(password)) {
        this.safety.showAll = 1
      }

      // 延迟检测隐藏全部选项
      if (delayPasswordRankTimer) clearTimeout(delayPasswordRankTimer)
      delayPasswordRankTimer = setTimeout(() => {
        if (!!this.safety.showFir && !!this.safety.showSec && !!this.safety.showThi && !!this.safety.showFour) {
          this.safety.hideAll = 1
        } else {
          this.safety.hideAll = 0
        }
      }, 200)
    },
    checkPsd(psd) {
      this.checkPasswordRank()
      return passwordPattren.test(psd)
    },
    getSubscribeSaParams (daId = '') {
      if (daId != '2-8-107') return {}
      return {
        subcribe_type: this.SIGNUP_SUBSCRIPTION_SUPPORT
          ? 'default_subcribe'
          : this.SIGNUP_SUBSCRIPTION_NO_SUPPORT
            ? 'default_not_subcribe'
            : '-',
        subcribe_check: this.checkEmailSubscrib ? 1 : 0
      }
    },
    async requestEmailSignupSer(params) {
      const query = querys()
      const activity_name = query.act_name || ''
      this.$evt.$emit('event-loading', true)
      const { isSwitch } = this.newUIStates
      isSwitch ? params.isSwitchUid = this.preloadData?.uid : ''
      params.password_confirm = params.password
      params.register_type = 'email'
      params.activity_name = activity_name
      // 极验验证
      const res = await geetestChallenge(emailSignupSer, params, 'register')
      if (!res) {
        this.$evt.$emit('event-loading', false)
        return
      }
      const { apiResData = {}, status } = res
      if (status == 'error') {
        this.aliasTip.show = true
        this.aliasTip.txt = this.langText.SHEIN_KEY_PWA_16274
        this.$evt.$emit('event-loading', false)
        return
      }
      if (status == 'close') {
        this.$evt.$emit('event-loading', false)
        return
      }
      const response = apiResData || {}
      const { code, info } = response
      if (params.daId) {
        saSend(params.daId, {
          mode: 'email',
          code,
          acc: this.relatedAcc,
          ...this.getSubscribeSaParams(params.daId),
        })
      } else {
        sensorsSend('2-8-78', { code, type: 'email', subscribe: this.checkEmailSubscrib })
      }
      if (params.check_register_email_code) {
        saSend('2-8-111', { code, scene: 'register' })
      }
      Metric_Register_Detail_Click_Result_Total({ account_type: 'email', sub_site: SiteUID || '', result: code == 0 ? 'success' : 'failed', error: code })
      // 风控检测
      if (this.$refs?.temporaryRef?.detectRiskChalleage(response, { riskCode: 402908, params, message_type: 'register_confirm', scene: 'register' }, ({ type, params }) => {
        if (type == 'close') return
        this.requestEmailSignupSer(params)
      })) {
        this.$evt.$emit('event-loading', false)
        return
      }

      if (code == 0) {
        this.setnewUIStates({ showType: 'continue', isToLogin: false, isSwitch: false })
        reportSignupMarketing('Email')
        // 默认订阅，勾选表示不订阅
        if (params.is_subscribe == '1') {
          postAddSubscribe({
            subscribe_type: '1',
            alias: params.email,
            scene_type: '1'
          })
        }

        // 邮箱注册引导手机绑定
        if (this.phoneAbt.registerBindPhone == 'on' && !isFromShopping()) {
          this.$evt.$emit('event-loading', false)
          this.setPhoneBind({ show: true, response })
          return
        }
        // 自动登录
        urlRedirection(false, response)
        return
      }
      if (code == -404 || code == -405) {
        this.aliasTip.txt = this.langText.SHEIN_KEY_PWA_14899
        this.aliasTip.show = true
        this.$evt.$emit('event-loading', false)
        // eslint-disable-next-line no-undef
        exceptionReport.geetest()
        return
      }
      if (code == 300206) { // 登出
        window.location.href = `${host}${langPath}/user/logout` // eslint-disable-line
        return
      }
      this.$evt.$emit('event-loading', false)
      if(code == 402922){
        riskVerifyChallenge(response, params, ({ paramsData, type }) => {
          if(type == 'error') {
            this.$evt.$emit('event-loading', false)
            this.$toast(this.langText?.SHEIN_KEY_PWA_14899)
            return
          }
          if(type == 'close') {
            this.$evt.$emit('event-loading', false)
            return
          }
          this.requestEmailSignupSer(paramsData)
        })
        return
      }
      if (code == 400503) {
        this.aliasTip.txt = this.langText.SHEIN_KEY_PWA_15590
        this.aliasTip.show = true
        return
      }

      if (code == 402914 && SiteUID == 'mph') {
        this.aliasTip.txt = this.langText.SHEIN_KEY_PWA_22822
        this.aliasTip.show = true
        return
      }

      if (code == 400569) { // 邮箱注册验证码验证
        const validateParams = {
          opts: {
            email: params.email,
            cb: (data) => {
              params.check_register_email_code = data
              this.requestEmailSignupSer(params)
            },
          },
          show: true,
        }
        this.changeDialogs({ key: 'signupEmailValidate', val: validateParams })
        return
      }

      if (code == 400517 || code == 400522) {
        this.aliasTip.txt = this.langText.SHEIN_KEY_PWA_15597
        this.aliasTip.show = true
        return
      }

      if (code == 400540) { // 注册账号与社交账号冲突
        const newVal = {
          show: true,
          type: 'third',
          email: params.email,
          password: params.password,
          source: 'Normal',
          repeatType: info.type,
          originType: 'third',
        }
        this.changeDialogs({ key: 'bindModalInfo', val: newVal })
        return
      }
      const errTip = formatNormalErrorMessage(response)

      this.aliasTip.txt = errTip
      this.aliasTip.show = true
    },
  }
}
