import { mapMutations, mapState } from 'vuex'
import { thirdLoginCodeSend, phoneCheckSer, checkRelationAccSer } from '../service'
import { geetestChallenge } from 'public/src/pages/common/RiskCommon/common/geetestChallenge.js'
import analysisIndex from '../analysis/index'
import { saSend } from '@login/analysis/util'
const { sensorsSend } = analysisIndex

export default {
  data: () => ({
    codeDownTime: 0,
    codeIsSended: false,
    is_relation: 0, // 是否已查询过关联账号
  }),
  computed: {
    ...mapState('login', ['areaCode', 'relatedAcc']),
  },
  watch: {
    'areaCode.code' () {
      this.$nextTick(() => {
        this.showInputTips(this.phoneTip, '', false)
      })
    }
  },
  methods: {
    ...mapMutations('login', ['setAreaCodePop']),
    handleCodeInputChange () {
      this.showInputTips(this.codeTip, '', false)
    },
    async handlePhoneBlurCheck () {
      const phone = this.phone
      const { code, abbr } = this.areaCode
      if (!phone) return
      const { code: validateCode } = await phoneCheckSer({ alias_type: 2, alias: phone, area_code: code, area_abbr: abbr })

      if (validateCode != 0) {
        this.showInputTips(this.phoneTip, this.langText.SHEIN_KEY_PWA_18690)
        return
      }
    },
    handlePhoneCheckInputParams () {
      const { phone, code } = this
      if (!phone) { // 手机号码
        this.showInputTips(this.phoneTip, this.langText.SHEIN_KEY_PWA_17075)
        return false
      }
      this.showInputTips(this.phoneTip, '', false)
      if (!code) {
        return false
      }
      this.showInputTips(this.codeTip, '', false)
      return true
    },
    handleAreaClick () {
      this.setAreaCodePop(true)
    },
    showInputTips (tip, msg, show = true) {
      tip.show = show
      tip.txt = msg
    },
    handlePhoneChange () {
      this.showInputTips(this.phoneTip, '', false)
    },
    handleCodeChange () {
      this.showInputTips(this.codeTip, '', false)
    },
    startDownTime (time) {
      const n = time <= 0 ? 0 : time
      this.codeDownTime = n
      if (n <= 0) return clearTimeout(this.downtimer)
      this.downtimer = setTimeout(() => {
        this.startDownTime(n - 1)
      }, 1000)
    },
    codeAnalysis (success) {
      const namesList =  ['phoneLoginItem', 'phoneSignupItem']
      if (!namesList.includes(this.name)) return
      sensorsSend('2-8-63', { type: this.name, success })
    },
    // 手机验证码发送
    async handleSendCode (scene = 'phone_login_register_verify') {
      const { code, abbr } = this.areaCode
      const phone = this.phone
      if (!this.phone) {
        return this.showInputTips(this.phoneTip, this.langText.SHEIN_KEY_PWA_17075)
      }

      if (this.phoneCodeIsSending) return
            
      this.phoneCodeIsSending = true
      const { code: validateCode } = await phoneCheckSer({ alias_type: 2, alias: phone, area_code: code, area_abbr: abbr })

      if (validateCode != 0) {
        this.phoneCodeIsSending = false
        this.showInputTips(this.phoneTip, this.langText.SHEIN_KEY_PWA_18690)
        return
      }

      const params = {
        alias_type: 2,
        alias: phone,
        scene,
        third_party_type: 8,
        area_code: code,
        area_abbr: abbr,
      }
      await this.requestSendCode(params)

    },
    async requestSendCode (params, smsType = 'sms') {
      // 极验验证
      const res = await geetestChallenge(thirdLoginCodeSend, params, 'send_message')
      if (!res) {
        this.phoneCodeIsSending = false
        this.$toast(this.langText.SHEIN_KEY_PWA_16274)
        return
      }
      const { apiResData = {}, status } = res
      if (status == 'error') {
        this.phoneCodeIsSending = false
        this.$toast(this.langText.SHEIN_KEY_PWA_16274)
        return
      }
      if (status == 'close') {
        this.phoneCodeIsSending = false
        return
      }
      const response = apiResData || {}
      const { code: sendCode, tips } = response
      saSend('2-8-101', {
        scene: 'forgot_password',
        mode: 'phone',
        send_type: smsType,
        sended: this.codeIsSended,
        code: sendCode
      })
      this.phoneCodeIsSending = false

      if (sendCode == 0) {
        this.startDownTime(60)
        this.codeIsSended = true
        this.$toast(this.langText.SHEIN_KEY_PWA_17775)
        return
      }
      this.$toast(tips || this.langText.SHEIN_KEY_PWA_14899)
    },
    async checkRelationAcc (params) {
      const data = {
        alias: params.alias,
        area_code: params.area_code,
        alias_type: 2,
        relation_token: this.relatedAcc.selected.relation_token,
      }
      const { code, tips, info } = await checkRelationAccSer(data)
      
      if (code == 0 && info?.result == 1) {
        return { pass: true }
      }
      
      if (code == 0 && info?.result == 0) { // 不匹配
        return { pass: false, tips: this.langText.SHEIN_KEY_PWA_21303 }
      }
      
      return { pass: false, tips: tips || this.langText.SHEIN_KEY_PWA_14899 }
      
    },
    /**
         * @description 手机号注册勾选订阅调用订阅接口
         * @date 2021-09-22 16:46
         * @param {object} params
         * 1:用户注册页、2:专题系统、3:PC页脚订阅、4:游戏系统、5:大会员会员管理、6:弹框注册、7:订阅中心、8:用户中台、9:第三方邮件服务、10:短信订阅页、11:支付成功页、12:订单详情页、13:绑定手机号界面
         * @param {number|string} scene_type - 订阅场景
        */
    async handlePostAddSubscribe(params = {}, scene_type = '1') {
      const { postAddSubscribe } = await import('public/src/pages/common/biz_helper/subscribe')
      postAddSubscribe({
        subscribe_type: '2',
        alias: params.alias,
        scene_type: scene_type || '1',
        area_code: params.area_code,
        country: params.area_abbr,
        verify: '1'
      })
    }
  }
}
