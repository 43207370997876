<template>
  <div
    class="code__input-area"
    :class="emailCodeTip.txt ? 'email-error' : ''"
  >
    <label for="registerPhoneCode">{{ langText.SHEIN_KEY_PWA_17724 }}</label>
    <div class="input-filed">
      <input
        id="registerPhoneCode"
        v-model="emailCode"
        type="text"
        :placeholder="langText.SHEIN_KEY_PWA_18691"
        @input="handleInputChange"
      />
      <s-button
        v-show="emailCodeDownTime <= 0"
        class="code-btn"
        :type="['primary', 'H48PX']"
        @click="handleSendEmailCode('forget_email_verify')"
      >
        {{ emailCodeIsSended ? langText.SHEIN_KEY_PWA_14897 : langText.SHEIN_KEY_PWA_17205 }}
      </s-button>
      <s-button
        v-show="emailCodeDownTime > 0"
        class="code-btn"
        disabled
        :type="['primary', 'H48PX']"
      >
        {{ emailCodeDownTime }}S
      </s-button>
    </div>
    <p
      v-show="emailCodeTip.show"
      class="code__error-tips"
    >
      {{ emailCodeTip.txt }}
    </p>
  </div>
</template>

<script setup>
import { ref, toRefs, defineExpose } from 'vue'
import { checkEmail } from '@login/util'
import useCommonHandle  from '@login/hooks/useCommonHandle.js'
import useSendCodeHook  from '@login/hooks/useSendCodeHook.js'

const props = defineProps({
  forgetSendEmailTip: {
    type: Object,
    default: () => {
      return { 
        show: false,
        txt: '' 
      } 
    }
  },
  email: {
    type: String,
    default: ''
  },
  langText: {
    type: Object,
    default: () => {
      return {}
    }
  }
})


const emailCode = ref('')
const emailCodeIsSending = ref(false)
const emailCodeIsSended = ref(false)
const emailCodeDownTime = ref(0)
const emailCodeTip = ref({
  show: false,
  txt: ''
})  

const { email, forgetSendEmailTip, langText } = toRefs(props)

const { requestSendCode } = useSendCodeHook({ codeIsSending: emailCodeIsSending, codeIsSended: emailCodeIsSended, codeDownTime: emailCodeDownTime, langText })
const { showInputTips } = useCommonHandle()

const handleInputChange = () => {
  showInputTips(emailCodeTip.value, '')
}

const handleSendEmailCode = (scene) => {
  const alias = email?.value
  if (!checkEmail(alias, forgetSendEmailTip?.value)) {
    return
  }
  if(emailCodeIsSending.value) return
  emailCodeIsSending.value = true
  const params = {
    alias_type: 1,
    alias,
    scene,
    third_party_type: 7
  }
  requestSendCode(params)
}

const handleCheckInputCode = () => {
  if (!/^.{6}$/.test(emailCode.value)) {
    showInputTips(emailCodeTip.value, langText.value?.SHEIN_KEY_PWA_18691)
    return false
  }
  return true
}


defineExpose({
  emailCode,
  emailCodeTip,
  handleCheckInputCode
})
// eslint-disable-next-line semi
;
</script>

<script>
export default {
  name: 'EmaiCodeInputIndex',
}
</script>

<style lang="less" scoped>
.input-filed {
  height: 34px;
  line-height: 34px;
  display: flex;
  span {
    .font-dpr(28px);
    color: #222222;
    .margin-r(22px);
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  input {
    flex: 1;
    border: 0;
    .font-dpr(28px);
  }


  .password-icon {
    color: #959595;
  }
}
.code__input-area{
  border-bottom: 1px solid #e5e5e5;
  &.email-error{
    border-color: #BF4123;
  }
  label {
    color: #999999;
    .font-dpr(28px);
    position: relative;
    padding-top: 17px;
    display: block;
    .label-title {
      position: absolute;
      .left(0);
      top: 50%;
      transform: translateY(-50%);
      transition: all 0.1s linear;
      text-transform: uppercase;
      color: #767676;
    }
    /* stylelint-disable-next-line selector-max-type */
    input {
      border: 0;
      width: 100%;
      line-height: 1.1;
      padding: 9px 0;
      color: #222;
    }
  }
  .code__error-tips{
    color: #BF4123;
    line-height: 1.2;
    padding: 0.12rem 0;
  }
  button{
    /* stylelint-disable-next-line declaration-no-important */
    margin: 0 !important;
  }
}
</style>
