import { useToast } from '@login/hooks/utils'
import { geetestChallenge } from 'public/src/pages/common/RiskCommon/common/geetestChallenge.js'
import { thirdLoginCodeSend } from '@login/service'
import { saSend } from '@login/analysis/util'

let downtimer = null

export default function useSendCodeHook ({ codeIsSending, codeIsSended, codeDownTime, langText }) {

  const $toast = useToast()

  const requestSendCode = async (params, smsType = 'sms') => {
    // 极验验证
    const res = await geetestChallenge(thirdLoginCodeSend, params, 'send_message')
    if (!res) {
      codeIsSending.value = false
      $toast(langText?.value?.SHEIN_KEY_PWA_16274)
      return
    }
    const { apiResData = {}, status } = res
    if (status == 'error') {
      codeIsSending.value = false
      $toast(langText?.value?.SHEIN_KEY_PWA_16274)
      return
    }
    if (status == 'close') {
      codeIsSending.value = false
      return
    }
    const response = apiResData || {}
    const { code: sendCode, tips } = response
    saSend('2-8-101', {
      scene: params?.scene == 'forget_email_verify' ? 'forgot_password' : params?.scene,
      mode: params?.third_party_type == 7 ? 'email' : 'phone',
      send_type: params?.third_party_type == 7 ? 'email' : smsType,
      sended: codeIsSended.value,
      code: sendCode
    })
    codeIsSending.value = false
    if (sendCode == 0) {
      startDownTime(60)
      codeIsSended.value = true
      $toast(langText.value?.SHEIN_KEY_PWA_17775)
      return
    }
    $toast(tips || langText.value?.SHEIN_KEY_PWA_14899)
  }

  const startDownTime = (time) => {
    const n = time <= 0 ? 0 : time
    codeDownTime.value = n
    if (n <= 0) return clearTimeout(downtimer)
    downtimer = setTimeout(() => {
      startDownTime(n - 1)
    }, 1000)
  }

  return { requestSendCode }
}
