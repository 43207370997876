import { ref } from 'vue'
import { passwordPattren } from '@login/util'

let delayPasswordRankTimer = null

export default function usePasswordSafety ({ password }) {


  const safety = ref({
    showFir: 0,
    showSec: 0,
    showThi: 0,
    showAll: 0,
    showFour: 0,
    hideAll: 1
  })

  const handleCheckPasswordRank = () => {
    const passwordValue = password.value
    if (passwordValue.length >= 8) {
      safety.value.showFir = 1
    } else {
      safety.value.showFir = 0
    }
    if (/[a-zA-Z]+/.test(passwordValue)) {
      safety.value.showSec = 1
    } else {
      safety.value.showSec = 0
    }
    if (/[0-9]+/.test(passwordValue)) {
      safety.value.showThi = 1
    } else {
      safety.value.showThi = 0
    }
    if (/\s/g.test(passwordValue)) {
      safety.value.showFour = 0
    } else {
      safety.value.showFour = 1
    }
    if (!passwordPattren.test(passwordValue)) {
      safety.value.showAll = 1
    }

    // 延迟检测隐藏全部选项
    if (delayPasswordRankTimer) clearTimeout(delayPasswordRankTimer)
    delayPasswordRankTimer = setTimeout(() => {
      if (!!safety.value.showFir && !!safety.value.showSec && !!safety.value.showThi && !!safety.value.showFour) {
        safety.value.hideAll = 1
      } else {
        safety.value.hideAll = 0
      }
    }, 100)
  }
  

  return { safety, handleCheckPasswordRank }
}
