import { daEventCenter } from 'public/src/services/eventCenter/index'
daEventCenter.addSubscriber({ modulecode: '2-8' })


export const clickResetPasswordSubmit	= (code) => {
  daEventCenter.triggerNotice({
    daId: '2-8-112',
    extraData: {
      name: 'click_reset_password_submit',
      result: code == 0 ? 'success' : 'fail',
    },
  })
}
