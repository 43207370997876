<template>
  <s-drawer
    :visible="ResetPasswordModal.show"
    type="full"
    :no-header="true"
    :append-to-body="true"
    class="page-login__resetPassowrdModal"
    direction="rtl"
  >
    <s-title-nav :title="langText.SHEIN_KEY_PWA_15287">
      <template slot="preblock">
        <s-title-nav-item
          icon-class="iconfont-s icons-nav_back"
          @click.native="handleGoBack"
        />
      </template>
    </s-title-nav>
    <p class="page-login__resetPassowrd-top"></p>
    <div class="page-login__resetPassowrd-content">
      <div class="input-item">
        <s-field
          ref="inputRef"
          v-model="password"
          :type="isShowPassword ? 'text' : 'password'"
          :label="langText.SHEIN_KEY_PWA_15072"
          @input="handleInputChange"
        />
        <i
          class="iconfont"
          :class="isShowPassword ? 'icon-see-pass' : 'icon-pass' "
          style="color: #999999"
          @click="handleEyeBtn(1)"
        ></i>
      </div>
      <div
        v-show="!safety.hideAll"
        class="normal-info"
      >
        <div :class="{'normal-red' :safety.showAll}">
          <p :class="{'tips-greenp':safety.showFir}">
            · {{ langText.SHEIN_KEY_PWA_16140 }}
          </p>
          <p :class="{'tips-greenp':safety.showSec}">
            · {{ langText.SHEIN_KEY_PWA_16141 }}
          </p>
          <p :class="{'tips-greenp':safety.showThi}">
            · {{ langText.SHEIN_KEY_PWA_16142 }}
          </p>
          <p :class="{'tips-greenp':safety.showFour}">
            .  {{ langText.SHEIN_KEY_PWA_16892 }}
          </p>
        </div>
      </div>
      <div
        class="input-item"
      >
        <s-field
          ref="confirmInputRef"
          v-model="confirmPassword"
          :type="isShowConfirmPassword ? 'text' : 'password'"
          :label="langText.SHEIN_KEY_PWA_15595"
          @input="handleConfirmInputChange"
        />
        <i
          class="iconfont"
          :class="isShowConfirmPassword ? 'icon-see-pass' : 'icon-pass' "
          style="color: #999999"
          @click="handleEyeBtn(2)"
        ></i>
      </div>
      <s-button
        style="margin-top: 20px;"
        :disabled="buttonDisabled"
        width="100%"
        :type="['primary', 'H88PX']"
        @click="handleResetSubmit"
      >
        {{ langText.SHEIN_KEY_PWA_14896 }}
      </s-button>
    </div>
  </s-drawer>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useStore, useEventBus, useToast } from '@login/hooks/utils'
import { geetestChallenge } from 'public/src/pages/common/RiskCommon/common/geetestChallenge.js'
import { resetPasswordSer } from '@login/service'
import { clickResetPasswordSubmit } from '@login/utils/loginAnalysis/forgetPassword.js'
import usePasswordSafety from '@login/hooks/usePasswordSafety.js'

const store = useStore()
const evt = useEventBus()
const toast =  useToast()

const emit = defineEmits(['close'])

const inputRef = ref(null)
const confirmInputRef = ref(null)
const password = ref('')
const confirmPassword = ref('')
const buttonDisabled = ref(true)
const isShowPassword = ref(false)
const isShowConfirmPassword = ref(false)

const ResetPasswordModal = computed(() => store.state.login.dialogs.ResetPasswordModal)
const langText = computed(() => store.state.login.langText)

const { safety, handleCheckPasswordRank } = usePasswordSafety({ password })

const handleGoBack = () => {
  password.value = ''
  confirmPassword.value = ''
  store.commit('login/changeDialogs', { key: 'ResetPasswordModal', val: { show: false } })
}

const isDisableSubmitBtn = () => {
  if(safety.value.hideAll == 1 && password.value && confirmPassword.value){
    buttonDisabled.value = false
  }
}

const handleEyeBtn = (type) => {
  if (type == 1) {
    isShowPassword.value = !isShowPassword.value
    return
  }
  isShowConfirmPassword.value = !isShowConfirmPassword.value
}

const handleInputChange = async () => {
  await handleCheckPasswordRank()
  isDisableSubmitBtn()
}

const handleConfirmInputChange = () => {
  isDisableSubmitBtn()
  showInputTips(confirmInputRef.value, '', true)
}

const handleResetSubmit = () => {
  if (password.value !== confirmPassword.value) {
    showInputTips(confirmInputRef.value, langText.value?.SHEIN_KEY_PWA_15592)
    return
  }
  const { code: verification_code, alias } = ResetPasswordModal.value
  showInputTips(confirmInputRef.value, '', true)
  const params = {
    alias,
    alias_type: 1,
    verification_code,
    password: password.value,
    password_confirm: confirmPassword.value,
  }
  requestResetPassword(params)
}

const requestResetPassword = async (params) => {
  evt.$emit('event-loading', true)
  // 极验验证
  const res = await geetestChallenge(resetPasswordSer, params, 'send_message')
  if (!res) {
    evt.$emit('event-loading', false)
    showInputTips(inputRef.value, langText.value.SHEIN_KEY_PWA_16274)
    return
  }
  const { apiResData = {}, status } = res
  if (status == 'error') {
    evt.$emit('event-loading', false)
    showInputTips(inputRef.value, langText.value.SHEIN_KEY_PWA_16274)
    return
  }
  if (status == 'close') {
    evt.$emit('event-loading', false)
    return
  }
  const data = apiResData || {}
  const { code, tips } = data
  clickResetPasswordSubmit(code)
  evt.$emit('event-loading', false)
  if (code == 0) {
    toast(langText.value.SHEIN_KEY_PWA_15301)
    handleGoBack()
    emit('close')
    return
  }
  if (code == 400545) {
    showInputTips(confirmInputRef.value, tips)
    return
  }
  showInputTips(inputRef.value, tips || langText.value.SHEIN_KEY_PWA_14899)
}

const showInputTips = (target, msg, valid = false) => {
  target.updateErrorView({ valid: valid, validateMessage: msg })
}

// eslint-disable-next-line semi
;
</script>

<script>
export default {
  name: 'ResetPasswordModal',
}
</script>

<style lang="less" scoped>
/* stylelint-disable selector-max-specificity */
.page-login__resetPassowrdModal{
  .page-login__resetPassowrd-content{
    padding: 24px;
      .input-item {
        position: relative;
      i {
          font-size: 24px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          .right(0px);
      }
      
    } 
    .normal-info {
      color: #999;
      margin: 10px 0;
      .normal-red {
        color: #E64545;
        p {
          line-height: 1.5;
          &.tips-redp {
          color: @sui_color_unusual;
          }
          &.tips-greenp {
          color: @sui_color_safety;
          }
        }
      }
      > p {
        line-height: 1.5;
      }
    }
  }
  .page-login__resetPassowrd-top{
    background: #f6f6f6;
    width: 100%;
    height: 10px;
  }
}
</style>
